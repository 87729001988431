import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, WEB_ANALYTICS_PPVF_VOLUME } from "@kraftheinz/common";
import _ from "lodash";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Maintain Volume", "has-active": false, "has-filter-inactive": false, "search-by": "ItemCode", "ph-search-by": "Search by Item Code" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Volume for Country")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "Country", attrs: { "source": "countryId", "placeholder": "Country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "value": _vm.selectedCountry, "default-active-first-option": "" }, on: { "change": _vm.onCountryChange } })], 1), _c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Facility")]), _c("div", { staticClass: "facility-filter mr-2" }, [_c("select-filter", { key: "Facility", attrs: { "source": "facility", "reference": "web-analytics.common.ppvfw-facilities", "queries": [["CountryID", _vm.Country]], "placeholder": "Facility", "value": _vm.selectedFacility }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "Facility");
    } } })], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDelete(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("url-field", { key: "ItemCode", attrs: { "data-index": "itemCode", "title": "Item Code", "clickable": false, "base-url": "" } }), _c("text-field", { key: "Facility", attrs: { "data-index": "facility", "title": "Facility" } }), _c("text-field", { key: "DueDate", attrs: { "data-index": "dueDate", "title": "Due Date", "parse": _vm.parseDate } }), _c("text-field", { key: "Quantity", attrs: { "data-index": "quantity", "title": "Quantity" } })], 1);
};
var staticRenderFns$2 = [];
var Volume_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".country-filter[data-v-15da1907]{min-width:150px}.facility-filter[data-v-15da1907]{min-width:100px}.item-code-filter[data-v-15da1907]{width:150px}\n")();
const __vue2_script$2 = {
  name: "Volume",
  inject: ["crud", "can"],
  mixins: [MyUtil],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      },
      ItemDesc: "",
      ItemCode: "",
      Country: ""
    };
  },
  computed: {
    selectedItemCode() {
      const filter = this.crud.getQueryString("ItemCode");
      return filter || { value: void 0 };
    }
  },
  created() {
    this.getCountries(false);
  },
  methods: {
    parseDate(value) {
      if (!value)
        return;
      return this.$moment(`${value}`).format("YYYY/MM/DD");
    },
    async getItemsDesc() {
      if (this.ItemCode && this.Country) {
        const { data } = await this.axios.get(`${this.apiUrl}/web-analytics/common/ppvfw-item-desc?ItemCode=${this.ItemCode}&CountryID=${this.Country}`);
        if (!data) {
          this.ItemDesc = "";
          return;
        }
        this.ItemDesc = data.itemDesc;
      }
    },
    onItemCode: _.debounce(function(value) {
      if (value) {
        this.ItemCode = value;
        this.getItemsDesc();
        this.crud.setQueryString("ItemCode", value);
        this.crud.fetchList();
        return;
      }
      this.ItemCode = "";
    }, 400),
    onCountryChange(value) {
      if (value) {
        this.Country = value.value;
        this.onSelectFilterChange(value, "CountryID");
        this.getItemsDesc();
        return;
      }
    },
    onDelete(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete this Volume?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete this Volume?`,
        cancelText: "No",
        okText: "Delete",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.put(`${this.apiUrl}/web-analytics/input-volumes`, {
          CountryID: record.countryID,
          Currency: record.currency,
          ItemCode: record.itemCode,
          Facility: record.facility,
          DueDate: record.dueDate,
          Quantity: record.quantity,
          UpdateType: "delete"
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, "15da1907", null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var Volume = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Maintain Volume Overrides", "has-active": false, "has-filter-inactive": false, "search-by": "ItemCode", "ph-search-by": "Search by Item Code" }, scopedSlots: _vm._u([_vm.can(_vm.permissions.maintain) ? { key: "create-buttons", fn: function() {
    return [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onAddClick } }, [_vm._v("Add Volume Override")])];
  }, proxy: true } : null, { key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Volume for Country")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "Country", attrs: { "source": "countryId", "placeholder": "Country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "value": _vm.selectedCountry, "default-active-first-option": "" }, on: { "change": _vm.onCountryChange } })], 1), _c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Facility")]), _c("div", { staticClass: "facility-filter mr-2" }, [_c("select-filter", { key: "Facility", attrs: { "source": "facility", "reference": "web-analytics.common.ppvfw-facilities", "queries": [["CountryID", _vm.Country]], "placeholder": "Facility", "value": _vm.selectedFacility }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "Facility");
    } } })], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDelete(record);
    } } })], 1) : _vm._e()];
  } }], null, true) }, [_c("url-field", { key: "ItemCode", attrs: { "data-index": "itemCode", "title": "Item Code", "clickable": false, "base-url": "" } }), _c("text-field", { key: "Facility", attrs: { "data-index": "facility", "title": "Facility" } }), _c("text-field", { key: "DueDate", attrs: { "data-index": "dueDate", "title": "Due Date", "parse": _vm.parseDate } }), _c("text-field", { key: "Quantity", attrs: { "data-index": "quantity", "title": "Quantity" } })], 1);
};
var staticRenderFns$1 = [];
var VolumeOverride_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".country-filter[data-v-159a67a3]{min-width:150px}.facility-filter[data-v-159a67a3]{min-width:100px}.item-code-filter[data-v-159a67a3]{width:150px}\n")();
const __vue2_script$1 = {
  name: "VolumeOverride",
  inject: ["crud", "createRoute", "can"],
  mixins: [MyUtil],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      },
      ItemDesc: "",
      ItemCode: "",
      Country: ""
    };
  },
  computed: {
    selectedItemCode() {
      const filter = this.crud.getQueryString("ItemCode");
      return filter || { value: void 0 };
    }
  },
  created() {
    this.getCountries(false);
  },
  methods: {
    parseDate(value) {
      if (!value)
        return;
      return this.$moment(`${value}`).format("YYYY/MM/DD");
    },
    async getItemsDesc() {
      if (this.ItemCode && this.Country) {
        const { data } = await this.axios.get(`${this.apiUrl}/web-analytics/common/ppvfw-item-desc?ItemCode=${this.ItemCode}&CountryID=${this.Country}`);
        if (!data) {
          this.ItemDesc = "";
          return;
        }
        this.ItemDesc = data.itemDesc;
      }
    },
    onItemCode: _.debounce(function(value) {
      if (value) {
        this.ItemCode = value;
        this.getItemsDesc();
        this.crud.setQueryString("ItemCode", value);
        this.crud.fetchList();
        return;
      }
      this.ItemCode = "";
    }, 400),
    onCountryChange(value) {
      if (value) {
        this.Country = value.value;
        this.onSelectFilterChange(value, "CountryID");
        this.getItemsDesc();
        return;
      }
    },
    onDelete(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete this Volume Override?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete this Volume Override?`,
        cancelText: "No",
        okText: "Delete",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.put(`${this.apiUrl}/web-analytics/input-volume-overrides`, {
          CountryID: record.countryID,
          Currency: record.currency,
          ItemCode: record.itemCode,
          Facility: record.facility,
          DueDate: record.dueDate,
          Quantity: record.quantity,
          UpdateType: "delete"
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    },
    onAddClick() {
      this.$router.push(this.createRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "159a67a3", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var VolumeOverride = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("a-tabs", { staticClass: "neutral-1", attrs: { "v-model": "volume" } }, [_c("a-tab-pane", { key: "volume", attrs: { "tab": "Volume" } }, [_c("resource", { attrs: { "name": "web-analytics.common.facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppvfw-facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.input-volumes", "api-url": _vm.apiUrl, "resource-id-name": "priceID", "edit-route": "/web-analytics/volume/:id", "page": _vm.page } }, [_c("volume")], 1)], 1), _c("a-tab-pane", { key: "volume-override", attrs: { "tab": "Volume Overrides" } }, [_c("resource", { attrs: { "name": "web-analytics.common.facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppvfw-facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.input-volume-overrides", "api-url": _vm.apiUrl, "resource-id-name": "priceID", "create-route": "/web-analytics/volume/volume-override/create", "edit-route": "/web-analytics/volume/volume-override/:id", "page": _vm.page } }, [_c("volume-override")], 1)], 1)], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    Volume,
    VolumeOverride
  },
  data() {
    return {
      page: WEB_ANALYTICS_PPVF_VOLUME,
      Volume,
      VolumeOverride,
      apiUrl,
      itemsMenu: [
        {
          key: "ppv-forecast",
          title: "PPV-Forecast",
          path: ""
        },
        {
          key: "extra-costs",
          title: "Maintain Volume/Volume Overrides",
          path: "/web-analytics/volume"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
